<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div></div>
</template>


<script>
import LoginAuth0 from './LoginAuth0.vue'

export default {
  components: {
    LoginAuth0
  },
  data () {
    return {
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION || 'local',
      userRolesDic: {
        'admin': 'admin',
        'head-of-fleet': 'team-leader',
        'finance': 'accounting',
        'manager': 'warehouse-agent',
        'customer-support': 'customer-support',
        'transporter': 'traveller',
        'merchant': 'merchant',
        'growth-hacker': 'growth-hacker',
        'fc-operations-supervisor': 'fc-operations-supervisor',
        'fc-receiving-agent':'fc-receiving-agent',
        'fc-inbounding-agent':'fc-inbounding-agent',
        'fc-storing-agent': 'fc-storing-agent',
        'fc-packing-agent': 'fc-packing-agent',
        'fc-picking-agent': 'fc-picking-agent',
        'sales': 'sales',
        'ops-admin': 'ops-admin',
        'commercial-admin': 'commercial-admin'
      }
    }
  },
  created () {
    if (!this.$cookies.get('language')) {
      this.$cookies.set('language', 'ar', '100y')
    }
    if (this.$auth.isAuthenticated()) {
      if (this.$store.state.AppActiveUser.userRole === 'customer-agent' || this.$store.state.AppActiveUser.userRole === 'merchant-agent') {
        this.$router.push(`/${this.$cookies.get('language')}/${this.$store.state.AppActiveUser.userRole}/task-list`).catch(() => {})
      } else {
        this.$router.push(`/${this.$cookies.get('language')}/${this.userRolesDic[this.$store.state.AppActiveUser.userRole]}/dashboard`).catch(() => {})
      }
    }
    this.$auth.login({ target: this.$route.query.to })
  }
}
</script>
