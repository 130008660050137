<template>
  <vs-button class="mb-4 ml-2" @click="loginAuth0" color="#eb5424">Click to Login</vs-button>
</template>

<script>
export default {
  data () {
    return {
      userRolesDic: {
        'admin': 'admin',
        'head-of-fleet': 'team-leader',
        'finance': 'accounting',
        'manager': 'warehouse-agent',
        'customer-support': 'customer-support',
        'transporter': 'traveller',
        'merchant': 'merchant',
        'growth-hacker': 'growth-hacker',
        'fc-operations-supervisor': 'fc-operations-supervisor',
        'fc-receiving-agent': 'fc-receiving-agent',
        'fc-inbounding-agent':'fc-inbounding-agent',
        'fc-storing-agent': 'fc-storing-agent',
        'fc-packing-agent': 'fc-packing-agent',
        'fc-picking-agent': 'fc-picking-agent',
        'sales': 'sales',
        'ops-admin': 'ops-admin',
        'commercial-admin': 'commercial-admin'
      }
    }
  },
  methods: {
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },

    loginAuth0 () {
      if (!this.$cookies.get('language')) {
        this.$cookies.set('language', 'ar', '100y')
      }
      if (this.$store.state.auth.isUserLoggedIn()) { 
        if (this.$store.state.AppActiveUser.userRole === 'customer-agent' || this.$store.state.AppActiveUser.userRole === 'merchant-agent') {
          this.$router.push(`/${this.$cookies.get('language')}/${this.$store.state.AppActiveUser.userRole}/task-list`).catch(() => {})
        } else {
          this.$router.push(`/${this.$cookies.get('language')}/${this.userRolesDic[this.$store.state.AppActiveUser.userRole]}/dashboard`).catch(() => {})
        }
      } else {
        this.$auth.login({ target: this.$route.query.to })
      }
    }
  }

}

</script>
